  var Map = (function (jQuery,window,document) {
    var that;
    var mapCenters = {
      //  Yvelines :        48.7615425,1.2758239,9z
      78 : {
        long : 1.918342709541321,
        lat : 48.84270708070195,
        zoom : 10
      },
      //  Hauts-de-Seine :  48.8399127,2.1006529,11z
      92 : {
        long : 48.8399127,
        lat : 2.1006529,
        zoom : 11
      },
      //  Val d oise :      49.0754233,1.8209804,10z
      95 : {
        long : 49.0754233,
        lat : 1.8209804,
        zoom : 9
      },
    };
  
    var coordinates = [mapCenters[window.dpt].lat, mapCenters[window.dpt].long];
    var zoom = mapCenters[window.dpt].zoom;
    
    var mapMarkerIcon_baseUrl = WPURLS.siteurl + '/wp-content/themes/cdad/dist/images/';
    var mapMarkerIconSelected = mapMarkerIcon_baseUrl + 'pin_selected.png';
    var mapMarkerIcon = {
      'mjd' : 'home.png',
      'pad' : 'guichet.png',
      'rad' : 'panneau.png',
      'rc' : 'heart.png',
    };
    var mapMarkersList = [];
    var dptMarkersList = {
      'mjd' : [],
      'pad' : [],
      'rad' : [],
      'rc' : [],
    };
    
    var Map = function () {
      //public properties shared across instances
      that = this;
      that.name = 'Map';
    };
    
    // public static
    Map.staticMethod = function () {
    };
    
    Map.prototype = {
      init:function()
      {
        if(jQuery('body').hasClass('home')) {
          that.initMap();
        }
      },
      
      initMap: function() {
        
        var container = jQuery('#map-wrapper');
        // CREATE MAP
        map = L.map('map-wrapper').setView(coordinates, zoom);
  
        L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
          attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox.light',
          accessToken: 'pk.eyJ1IjoiYWt5b3NkZXYiLCJhIjoiY2swZ3JrMHljMDBjYjNlbzM0eWR0eXJ6eSJ9.5zuzUwF4ZyS0OxBCdbF3AQ'
        }).addTo(map);
  
        // Load kml file
        var kml_file_name = WPURLS.siteurl + '/wp-content/themes/cdad/assets/kml/' + window.dpt + '.kml';
        var client = new XMLHttpRequest();
        client.open('GET', kml_file_name);
        client.onreadystatechange = function() {
          const parser = new DOMParser();
          const kml = parser.parseFromString(client.responseText, 'text/xml');
          const track = new L.KML(kml);
          map.addLayer(track);
        };
        client.send();
        
        jQuery.ajax({
          'url' : ajaxurl,
          'action': 'get_poi',
          'method': 'POST',
          'data':   {
            'action': 'get_poi'
          }
        }).done(function(data){
          var locations = JSON.parse(data);
          
          locations.forEach( function(location) {
            var locationIcon = L.icon({
              iconUrl: mapMarkerIcon_baseUrl + mapMarkerIcon[location.type],
              iconSize: [25, 25],
              iconAnchor: [5, 5],
              popupAnchor: [-3, -20]
            });
  
            var markerPoints = new L.LatLng(location.lat, location.lng);
            var marker = L.marker(markerPoints, {icon: locationIcon}).addTo(map);
  
            mapMarkersList.push(marker);
            dptMarkersList[location.type].push(marker);
            var popupcontent = '';
            
            if(location.title !== null){
              popupcontent += '<strong class="text-uppercase">' + location.title + '</strong>';
            }
            if(location.address !== null){
              popupcontent += '<div class="font-weight-bold">' + location.address + '</div>';
            }
            if(location.opening !== null){
              popupcontent += '<div class="font-weight-bold">' + location.opening + '</div>';
            }
            if(location.contact !== null){
              popupcontent += '<div class="font-weight-bold">' + location.contact + '</div>';
            }
            if(location.type === 'rc'){
              popupcontent += '<div  class="font-weight-bold"><a href="' + WPURLS.avocats_dc_url + '">Plus d\'informations</a></div>';
            }else{
              popupcontent += '<div  class="font-weight-bold"><a href="' + location.link + '">Plus d\'informations</a></div>';
            }
            
            marker.bindPopup(popupcontent);
          });
        });
        
        var filters = jQuery('.filters-map .filter-map');
        filters.on('click', function() {
          
          if(jQuery(this).data('type').length) {
            mapMarkersList.forEach(function(marker) {
              marker.setOpacity(0);
            });
            
            dptMarkersList[jQuery(this).data('type')].forEach(function(marker) {
              marker.setOpacity(1);
            });
          } else {
            mapMarkersList.forEach(function(marker) {
              marker.setOpacity(1);
            });
          }
          that.changeMarkersOnZoom();
        });
        
        map.on("zoom", function() {
          that.changeMarkersOnZoom();
        });
      },
      
      changeMarkersOnZoom: function() {
        var zoom = map.getZoom();
        
        if(zoom > 9) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "25px");
          jQuery('.leaflet-marker-icon').css("height", "25px");
        }
        if(zoom == 9) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "20px");
          jQuery('.leaflet-marker-icon').css("height", "20px");
        }
        if(zoom == 8) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "16px");
          jQuery('.leaflet-marker-icon').css("height", "16px");
        }
        if(zoom == 7) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "12px");
          jQuery('.leaflet-marker-icon').css("height", "12px");
        }
        if(zoom == 6) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "8px");
          jQuery('.leaflet-marker-icon').css("height", "8px");
        }
        if(zoom == 5) {
          jQuery('.leaflet-marker-icon').css("display", "block");
          jQuery('.leaflet-marker-icon').css("width", "5px");
          jQuery('.leaflet-marker-icon').css("height", "5px");
        }
        if(zoom < 5) {
          jQuery('.leaflet-marker-icon').css("display", "none");
        }
      }
    };
    return Map;
  })(jQuery,window,document);
  
  window.map = new Map();
  jQuery(document).ready(function () {
    window.map.init();
  });
